import * as React from "react"

import { Container } from "../../components"
import { Category } from "../../section"
import Seo from "../../components/seo"

const Healthcare = () => (
  <Container>
    <Seo
      title="ヘルスケア"
      description="大人の色気のある男性は見えるところだけでなく、内側からしっかりケアされています。健康的な身体を作り上げていくには日々のトレーニングや食事がとても大切になってきます。ここでは内面的にもキレイになるための知識やおすすめ商品を紹介します。"
    />
    <Category
      Title="Healthcare"
      SubTitle="ヘルスケア"
      theCategory="lifestyle"
      theCategory2="bodymake"
    />
  </Container>
)

export default Healthcare
